import React from 'react';

import { Box, useTheme } from '@mui/material';
import Popover from 'material-ui-popup-state/HoverMenu';
import { useTranslation } from 'next-i18next';

import Button from '@components/atoms/button';
import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import UniversityMasterDegreeContentListing from '@components/molecules/universityMasterDegreeContentListing';
import usePopoverState from '@hooks/usePopoverState';

const UniversityMasterDegreeMenuPopover = () => {
  const { t } = useTranslation('common');
  const { bindHover, bindMenu } = usePopoverState({ id: 'university-master-degree-popover' });
  const theme = useTheme();

  return (
    <>
      <Button sx={{ color: theme.palette.common.black }} {...bindHover}>
        <Box display="flex" alignItems="center" gap={1}>
          {t('menu.master_degree')}
          <FontAwesomeIcon icon="fa-light fa-angle-down" style={{ fontSize: 18 }} />
        </Box>
      </Button>
      <Popover
        {...bindMenu}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box width={400} px={2} py={1}>
          <UniversityMasterDegreeContentListing />
        </Box>
      </Popover>
    </>
  );
};

export default UniversityMasterDegreeMenuPopover;
