import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const NotificationListStyle = styled(Box)(
  ({ theme }) => `
  .load-more-button {
    color: ${theme.palette.primary.main};

    p {
      display: flex;
      align-items: center;
    }

    i {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  `,
);

export default NotificationListStyle;
