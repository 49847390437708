import { useDispatch } from 'react-redux';

import { useSWRConfig } from 'swr';

import { getToken } from '@helpers/auth';
import { useRequest, useBooleanReduxState } from '@helpers/use';
import { IUserProfile } from '@interfaces/user';
import appActions from '@redux/app/actions';
import userAPI, { USER_END_POINT } from 'src/api/user';

const { set_user_menu_drawer_open } = appActions;

export const useNotification = () => {
  const USER_NOTIFY = `${USER_END_POINT.USER_ME}/notify`;
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const { data: profile, isLoading, isValidating } = useRequest<IUserProfile>(
    getToken() ? USER_NOTIFY : null,
    userAPI.fetchProfile,
  );

  const [, setIsNotificationDialogOpen] = useBooleanReduxState(
    'isNotificationDialogOpen',
    'set_notification_dialog_open',
  );

  const seeNotification = () => {
    const { is_notification_seen, latest_notification_id } = profile!;
    const data = {
      ...profile!,
      is_notification_seen: true,
    };
    if (latest_notification_id && !is_notification_seen) {
      mutate(USER_NOTIFY, { data }, false);
      userAPI.seenNotification(latest_notification_id);
    }
  };

  const handleOpenNotificationModal = () => {
    dispatch(set_user_menu_drawer_open(false));
    seeNotification();
    setIsNotificationDialogOpen(true);
  };

  return {
    isNotificationSeen: profile ? profile.is_notification_seen : true,
    isLoading: isLoading && isValidating,
    isValidating,
    seeNotification,
    handleOpenNotificationModal,
  };
};
