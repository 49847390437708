import Skeleton from '@mui/material/Skeleton';

import LogoStyle from './style';

import Image from 'src/components/atoms/image';
import config from 'src/config';
import { useAppData } from 'src/helpers/use';

const Logo = () => {
  const { appData, isLoading } = useAppData();
  const logo = appData?.logo;

  return (
    <LogoStyle className="logo">
      {isLoading || !logo ? (
        <Skeleton variant="rectangular" width={140} height={44} />
      ) : (
        <a target={logo.target_url} href={logo.link_url || '/'}>
          <Image
            alt="SkillLane"
            src={`${config.IMAGE_CDN_HOST}/images/Logo/logo.png`}
            errorImage={`${config.IMAGE_CDN_HOST}/assets/logo.png`}
          />
        </a>
      )}
    </LogoStyle>
  );
};

export default Logo;
