import { useEffect } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'next-i18next';
import { useOrientation } from 'react-use';

import MyCourseMenuStyle from './style';

import Button from 'src/components/atoms/button';
import Image from 'src/components/atoms/image';
import NextLink from 'src/components/atoms/link';
import config from 'src/config';
import { ROUTES } from 'src/helpers/constants';
import { useWindowSize, useProfile } from 'src/helpers/use';

const MyCourseMenu = () => {
  const { profile } = useProfile();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { type: orientationType } = useOrientation();
  const popupState = usePopupState({ variant: 'popover', popupId: 'myCourseMenu' });

  useEffect(() => {
    if (popupState.isOpen) popupState.close();
  }, [width]);

  useEffect(() => {
    if (popupState.isOpen) popupState.close();
  }, [orientationType]);

  return (
    <>
      {profile!.is_cpd ? (
        <>
          <Button data-testid="myCourseButton" variant="outlined" color="primary" {...bindHover(popupState)}>
            {t('my_course')} <KeyboardArrowDownIcon />
          </Button>
          <MyCourseMenuStyle
            {...bindMenu(popupState)}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="myCourseMenu"
          >
            <NextLink href={ROUTES.MY_COURSE}>
              <MenuItem key="skilllane_course" dense>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Image
                    className="logo"
                    src={`${config.IMAGE_CDN_HOST}/assets/logos/b2c.png`}
                    alt="skilllane-b2c-logo"
                  />
                  <Typography variant="h7lanna">{t('skilllane_course')}</Typography>
                </Box>
              </MenuItem>
            </NextLink>
            <NextLink href={config.CPD_DOMAIN}>
              <MenuItem key="skilllane_course_cpd" dense>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Image
                    className="logo"
                    src={`${config.IMAGE_CDN_HOST}/assets/logos/cpd.png`}
                    alt="skilllane-cpd-logo"
                  />
                  <Typography variant="h7lanna">{t('skilllane_course_cpd')}</Typography>
                </Box>
              </MenuItem>
            </NextLink>
          </MyCourseMenuStyle>
        </>
      ) : (
        <NextLink href={ROUTES.MY_COURSE}>
          <Button data-testid="myCourseButton" variant="outlined" color="primary">
            {t('my_course')}
          </Button>
        </NextLink>
      )}
    </>
  );
};

export default MyCourseMenu;
