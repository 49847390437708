import { styled } from '@mui/material/styles';

export type HeaderStyleProps = {
  hideHeaderOnScroll: boolean;
};

const HeaderStyle = styled('div')<HeaderStyleProps>`
  position: ${(props: any) => (props.hideHeaderOnScroll ? 'relative' : 'sticky')};
  top: 0;
  z-index: 1001;
  background: white;

  .desktop-appbar {
    margin: auto;
    box-shadow: unset;
    max-width: 1730px;
    background: white;
    .logo {
      margin-right: 12px;
    }
    .category-menu-button {
      margin-right: 18px;
    }
    .divider {
      margin-right: 15px;
      margin-left: 5px;
      height: 18px;
    }
  }
  .mobile-appbar {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: white;
    padding: 0 1rem;

    .menu-icon-container {
      padding: 8px 0;
    }
    .menu-icon-container-right {
      display: flex;
      justify-content: flex-end;
      padding: 8px 0;
      .MuiTouchRipple-rippleVisible {
        opacity: 0;
      }
    }
    .search-container {
      padding: 0px 16px 16px 16px;
    }

    .alert-dot {
      background: #e31b0c;
      width: 10px;
      height: 10px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      right: 9px;
    }
  }

  @media (min-width: 2000px) {
    .desktop-appbar {
      max-width: 1730px;
    }
  }

  @media (max-width: 1999px) and (min-width: 1701px) {
    .desktop-appbar {
      max-width: 1440px;
    }
  }

  ${(props: any) => props.theme.breakpoints.up('lg')} {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    .mobile-appbar {
      display: none;
    }
    .desktop-appbar {
      .MuiToolbar-root {
        padding: 0px 12px;
      }
    }
  }

  ${(props: any) => props.theme.breakpoints.down('lg')} {
    .desktop-appbar {
      display: none;
    }
  }
  ${(props: any) => props.theme.breakpoints.down('md')} {
    .mobile-appbar {
      padding: 0 0.5rem;
    }
  }
`;

export default HeaderStyle;
