import { INotification } from 'src/interfaces/user';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';

import Box from '@mui/material/Box';

import 'dayjs/locale/th';
import Router, { useRouter } from 'next/router';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import userApi from 'src/api/user';
import { NOTIFICATION_TYPE, ROUTES } from 'src/helpers/constants';

import NotificationBoxStyle from './style';

dayjs.extend(relativeTime);

type NotificationBoxProps = {
  notification: INotification;
};

const NotificationBox = ({ notification }: NotificationBoxProps) => {
  const theme = useTheme();
  const router = useRouter();

  const { id, title, body, noti_type, created_at, read, course, course_id } = notification;
  const notificationType = noti_type as NOTIFICATION_TYPE;

  const getRelativeTime = (dateTime: string) => {
    dayjs.locale(router.locale);
    return dayjs(dateTime).fromNow();
  };

  const getContent = () => {
    if (notificationType === NOTIFICATION_TYPE.DISCUSSION) {
      return (
        <>
          <span className="title">{title}:</span>{' '}
          <span className="description">
            {course?.name || ''} "{body}"
          </span>
        </>
      );
    }
    return (
      <>
        <span className="title">{title}:</span> <span className="description">{body}</span>
      </>
    );
  };

  const getIcon = () => {
    if (
      [NOTIFICATION_TYPE.COURSE_APPROVED, NOTIFICATION_TYPE.COURSE_SAMPLE_VIDEO_APPROVED].includes(notificationType)
    ) {
      return <FontAwesomeIcon icon="fa-light fa-circle-check" color={theme.palette.secondary.main} />;
    }
    if (
      [NOTIFICATION_TYPE.COURSE_NOT_APPROVED, NOTIFICATION_TYPE.COURSE_SAMPLE_VIDEO_NOT_APPROVED].includes(
        notificationType,
      )
    ) {
      return <FontAwesomeIcon icon="fa-light fa-circle-xmark" color={theme.palette.error.main} />;
    }
    if (notificationType === NOTIFICATION_TYPE.DISCUSSION) {
      return <FontAwesomeIcon icon="fa-light fa-comment" color={theme.palette.secondary.main} />;
    }
    if (notificationType === NOTIFICATION_TYPE.WELCOME_REWARD_POINT) {
      return <FontAwesomeIcon sx={{ fontSize: '28px' }} icon="fa-light fa-gift" color={theme.palette.secondary.main} />;
    }
    return <FontAwesomeIcon icon="fa-light fa-exclamation" color={theme.palette.secondary.main} />;
  };

  const getUrl = () => {
    if (
      [
        NOTIFICATION_TYPE.COURSE_APPROVED,
        NOTIFICATION_TYPE.COURSE_SAMPLE_VIDEO_APPROVED,
        NOTIFICATION_TYPE.COURSE_NOT_APPROVED,
        NOTIFICATION_TYPE.COURSE_SAMPLE_VIDEO_NOT_APPROVED,
      ].includes(notificationType)
    ) {
      return ROUTES.MY_TEACHING;
    }
    if (notificationType === NOTIFICATION_TYPE.DISCUSSION && course_id) {
      return `/courses/${course_id}#discussion`;
    }
    if (notificationType === NOTIFICATION_TYPE.WELCOME_REWARD_POINT) {
      return ROUTES.MY_REWARD;
    }
    return '/';
  };

  const onClick = () => {
    userApi.readNotification(id);
    const url = getUrl();
    Router.push(url);
  };

  return (
    <NotificationBoxStyle className={read ? '' : 'active'} onClick={onClick}>
      <Box className="icon-container">
        <Box className="icon">{getIcon()}</Box>
      </Box>
      <Box className="message-container">
        <Typography className="content">{getContent()}</Typography>
        <Typography fontSize="16px" color="#676968">
          {getRelativeTime(created_at)}
        </Typography>
      </Box>
    </NotificationBoxStyle>
  );
};

export default NotificationBox;
