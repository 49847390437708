import { useEffect } from 'react';

import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import { useWindowSize, useOrientation } from 'react-use';

export type UsePopoverStateProps = {
  id?: string;
};
const usePopoverState = (props: UsePopoverStateProps) => {
  const { id } = props;

  const { width } = useWindowSize();
  const { type: orientationType } = useOrientation();
  const popupState = usePopupState({ variant: 'popover', popupId: id });

  useEffect(() => {
    if (popupState.isOpen) popupState.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    if (popupState.isOpen) popupState.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientationType]);

  return {
    popoverState: popupState,
    bindHover: bindHover(popupState),
    bindMenu: bindMenu(popupState),
  };
};

export default usePopoverState;
