import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import userAPI, { USER_END_POINT } from 'src/api/user';
import Button from 'src/components/atoms/button';
import NotificationBox from 'src/components/molecules/notification/notificationBox';
import { INotification } from 'src/interfaces/user';
import useSWRInfinite from 'swr/infinite';

import NotificationListStyle from './style';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';

const PAGE_SIZE = 10;

const NotificationList = () => {
  const { t } = useTranslation('common');

  const { data, size, setSize } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.data?.items.length) return null;

    const offset = pageIndex * PAGE_SIZE;

    return `${USER_END_POINT.MY_NOTIFICATIONS}?limit=${PAGE_SIZE}&offset=${offset}`;
  }, userAPI.fetchMyNotifications);

  const items = data ? data.map((response) => response.data?.items || []) : [];
  const count = data ? data[0].data?.count : 0;
  const notifications = [].concat(...items);

  return (
    <NotificationListStyle>
      {notifications.map((notification: INotification) => (
        <NotificationBox key={notification.id} notification={notification} />
      ))}
      {notifications.length === 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" p={3} borderTop="1px solid rgba(0, 0, 0, 0.12)">
          <Typography color="rgba(0, 0, 0, 0.38)" fontSize="18px">
            {t('no_notification')}
          </Typography>
        </Box>
      )}
      {notifications.length < count && (
        <Box display="flex" justifyContent="center" my={1}>
          <Button fullWidth onClick={() => setSize(size + 1)} className="load-more-button">
            <Typography>
              {t('load_more')} <FontAwesomeIcon icon="fa-regular fa-chevron-down" />
            </Typography>
          </Button>
        </Box>
      )}
    </NotificationListStyle>
  );
};

export default NotificationList;
