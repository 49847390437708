import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Menu from 'material-ui-popup-state/HoverMenu';

const NotificationBellStyle = styled(Box)(
  ({ theme }) => `
  color: black;
  display: flex;
  button {
    min-width: 40px;
    border-radius: 50%;
  }

  .alert-dot {
    background: #e31b0c;
    width: 10px;
    height: 10px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    right: 9px;
  }
  `,
);

export const NotificationPopup = styled(Menu)(
  ({ theme }) => `
  .MuiPaper-root {
    padding-top: 4px;
    background: none;
    box-shadow: none;
    width: 450px;
    overflow: hidden;
    
    ul {
      max-height: 421px;
      margin: 5px;
      padding: 16px 0px 0px 0px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
      border-radius: 4px;
      background: white;
      overflow-y: auto;
      overflow-x: hidden;
      
      ::-webkit-scrollbar {
        width: 8px;
        height: 0px;
      }
  
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  `,
);

export default NotificationBellStyle;
