import Router from 'next/router';
import { useEffect, useState } from 'react';

import { Box, Autocomplete, CardActionArea, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import searchAPI, { SEARCH_END_POINT } from 'src/api/search';
import Image from 'src/components/atoms/image';
import config from 'src/config';
import { useDebounce, useQuery, useWindowSize } from 'src/helpers/use';
import theme from 'src/theme';
import useSWR from 'swr';

import SearchBarStyle from './style';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';

type SearchResultProps = {
  props: any;
  option?: any;
  state?: any;
};

const redirectToSearchResultPage = (keyword: string, closeDrawer: () => void) => {
  if (keyword.trim().length) {
    closeDrawer();
    Router.push(`/courses/search?q=${encodeURIComponent(keyword.trim())}`);
  }
};

const onEnterByKeyboard = (event: any, keyword: string, onEnter: () => void, closeDrawer: () => void) => {
  if (event?.key === 'Enter') {
    onEnter();
    redirectToSearchResultPage(keyword, closeDrawer);
  }
};

export const SearchResult = ({ props, option, state }: SearchResultProps) => {
  const { t } = useTranslation('common');
  return (
    <CardActionArea>
      {option?.init ? (
        <div {...props} key={option.id_name}>
          <div className="d-flex-w-100">
            <Box className="mr-8">
              <div className="search-icon">
                <FontAwesomeIcon
                  className="icon-size"
                  color={theme.palette.primary.main}
                  icon="fa-light fa-magnifying-glass"
                />
              </div>
            </Box>
            <Box width="100%">
              <Typography className="title w-400" variant="h3">
                {`${option?.name} - ค้นหา`}
              </Typography>
            </Box>
          </div>
        </div>
      ) : (
        <div {...props} key={option.id_name}>
          <div className="d-flex-w-100">
            <Box className="mr-8">
              <Image
                className="preview-course-image"
                alt={option.name}
                src={option.highlight_image_small}
                errorImage={`${config.IMAGE_CDN_HOST}/assets/thumbnail-course-card.jpg`}
              />
            </Box>
            <Box width="100%">
              <Typography className="title" variant="h3">
                {option?.name}
              </Typography>
              <div className="instructor">
                <span className="w-700">{t('courses')}</span>
                {/* TODO: will back when solved query instructor name */}
                {/* <FontAwesomeIcon icon="fa-solid fa-circle" /> */}
                {option?.instructors_name.map((name: string, index: number) => (
                  <span key={`${name}_${index}`}>{name}</span>
                ))}
              </div>
            </Box>
          </div>
        </div>
      )}
    </CardActionArea>
  );
};

const SearchBar = ({ closeDrawer = () => {} }) => {
  const { width = 0 } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyWord] = useState('');
  const [result, setResult] = useState<any>([]);
  const isAlwayShowSearchResult = width < theme.breakpoints.values.lg;
  const debouncedSearch = useDebounce(keyword, 500);
  const [isInputByUser, setIsInputByUser] = useState(false);

  const queryString = useQuery();
  const { error, data: options, isValidating, mutate } = useSWR<any>(
    encodeURIComponent(debouncedSearch.trim()).length > 1
      ? `${SEARCH_END_POINT.RECOMMEND}?keyword=${encodeURIComponent(debouncedSearch.trim())}`
      : null,
    () => searchAPI.fetchCoursesByKeyword(keyword),
  );

  const handleOpen = (inputValue: string) => {
    // handle on mobile screen when click outside Dropdown should not close Dropdown
    if (isAlwayShowSearchResult || (!isAlwayShowSearchResult && inputValue.trim().length > 1 && isInputByUser)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleInputChange = (event: any, newInputValue: string, state: any) => {
    handleOpen(newInputValue);
    setKeyWord(newInputValue);
  };

  const onSelect = (event: any, value: any, state: string) => {
    if (state === 'clear') return;

    setIsOpen(false);
    if (value?.init) {
      return redirectToSearchResultPage(value?.id_name, closeDrawer);
    }
    closeDrawer();
    return Router.push(`/courses/${value?.id_name}`);
  };
  const handleClose = (event: any, resson: string) => {
    // handle on desktop screen when click outside Dropdown should close Dropdown
    if (isAlwayShowSearchResult) return;
    if (resson === 'blur') setIsOpen(false);
  };
  const loading = !error && !options && isValidating;
  useEffect(() => {
    if (!loading && options?.data) {
      setResult(options.data);
    } else {
      setResult([]);
    }
  }, [options]);

  useEffect(() => {
    if (queryString?.q) {
      setKeyWord(queryString?.q?.toString());
    }
  }, [queryString]);

  useEffect(() => {
    if (isOpen && !isAlwayShowSearchResult) {
      setIsOpen(false);
    }
  }, [width]);

  return (
    <SearchBarStyle>
      <Autocomplete
        open={isOpen}
        onClose={handleClose}
        value={keyword || null}
        disablePortal
        getOptionLabel={(option: any) => option?.name ?? option}
        options={result}
        loading={loading}
        filterOptions={(options: any, state: any) =>
          keyword.length > 1 ? [{ name: keyword, id_name: keyword, init: true }, ...options] : options
        }
        forcePopupIcon
        onInputChange={handleInputChange}
        onChange={(event, value, reason) => onSelect(event, value, reason)}
        freeSolo
        popupIcon={
          <FontAwesomeIcon
            className="popup-icon-search"
            color={theme.palette.primary.main}
            icon="fa-light fa-magnifying-glass"
            onClick={() => {
              setIsOpen(false);
              redirectToSearchResultPage(keyword, closeDrawer);
            }}
          />
        }
        renderOption={(props: any, options: any, state: any) => (
          <SearchResult key={options?.id_name} props={props} option={options} state={state} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={() => setIsOpen(true)}
            onKeyDown={(e) => {
              setIsInputByUser(true);
            }}
            onKeyPress={(event) => {
              onEnterByKeyboard(
                event,
                keyword,
                () => setIsOpen(false),
                () => closeDrawer(),
              );
            }}
            placeholder="ค้นหาคอร์สเรียน"
          />
        )}
      />
    </SearchBarStyle>
  );
};

export default SearchBar;
