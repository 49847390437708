import { useEffect, useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'next-i18next';

import { MenuLinkList } from '../menuLinkList';

import CategoryMenuStyle from './style';

import Button from 'src/components/atoms/button';
import { BOTTOM_MENU } from 'src/helpers/constants';
import { useAppData, useWindowSize } from 'src/helpers/use';
import { ICategory } from 'src/interfaces';

export default function CategoryMenu() {
  const { t } = useTranslation();
  const { appData } = useAppData();
  const categories = appData?.categories || [];
  const targetRef = useRef<any>();
  const [category, setCategory] = useState<ICategory | null>(null);

  const { width } = useWindowSize();
  const popupState = usePopupState({ variant: 'popover', popupId: 'categoryMenu' });
  const popupSubcategoryState = usePopupState({ variant: 'popover', popupId: 'subcategoryMenu' });

  useEffect(() => {
    if (popupState.isOpen) {
      setCategory(null);
      popupState.close();
    }
  }, [width]);

  useEffect(() => {
    if (!popupState.isOpen || !popupSubcategoryState.isOpen) {
      setCategory(null);
    }
  }, [popupState.isOpen, popupSubcategoryState.isOpen]);

  return (
    <>
      <Button
        data-testid="categoryButton"
        className="category-menu-button"
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        {...bindHover(popupState)}
        aria-haspopup="true"
      >
        {t('category')} <KeyboardArrowDownIcon />
      </Button>
      <div style={{ display: 'flex' }}>
        <CategoryMenuStyle
          {...bindMenu(popupState)}
          marginThreshold={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          data-testid="categoryMenu"
        >
          <Box ref={targetRef}>
            <Box {...bindHover(popupSubcategoryState)}>
              <MenuLinkList
                list={categories}
                hoverCategoryId={category?.id}
                isDesktop
                setCategory={setCategory}
                isOpenMenu={popupState.isOpen}
              />
            </Box>
            <Box my={1}>
              <Divider />
            </Box>
            <MenuLinkList list={BOTTOM_MENU} />
          </Box>

          {category && (
            <CategoryMenuStyle
              {...bindMenu(popupSubcategoryState)}
              subcategory
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              data-testid="subcategoryMenu"
            >
              <div style={{ height: targetRef?.current?.offsetHeight }}>
                <MenuLinkList list={category?.children} isSubcategoryMenu />
              </div>
            </CategoryMenuStyle>
          )}
        </CategoryMenuStyle>
      </div>
    </>
  );
}
