import { withTheme } from '@mui/styles';
import styled from 'styled-components';

const SearchBarStyle = withTheme(styled.div`
  z-index: 1002;
  top: 0;
  flex: 1 0.99 auto;
  margin-right: 16px;

  .MuiAutocomplete-popupIndicator {
    padding: 4px;
  }
  .Mui-focused {
    input:focus::placeholder {
      color: transparent;
    }
  }
  .popup-icon-search {
    padding: 2px;
    font-size: 20px;
  }
  .search-icon {
    width: 38px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .preview-course-image {
    height: 38px;
    width: 38px;
    border-radius: 4px;
    object-fit: none;
    object-position: center;
  }
  .d-flex-w-100 {
    display: flex;
    width: 100%;
  }
  .MuiAutocomplete-listbox {
    max-height: 450px;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .w-400 {
    font-weight: 400;
  }

  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    font-size: 18px;
    line-height: 22px;
    word-break: break-all;
  }
  .instructor {
    text-align: left;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    font-size: 14px;
    line-height: 22px;
    word-break: break-all;
    align-items: center;
    font-family: Lanna;
    color: rgba(0, 0, 0, 0.6);
    .w-700 {
      font-weight: 700;
    }
    svg {
      font-size: 3px;
      margin: 1.5px 3px;
    }
    span:not(:first-child):not(:last-child):after {
      margin-right: 3px;
      content: ',';
    }
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0 4px 0 6px;
  }

  .MuiAutocomplete-inputRoot {
    padding: 0 39px 0 9px !important;
    height: 40px;
  }

  .MuiAutocomplete-endAdornment {
    top: 4.5px;
  }
  .MuiInputLabel-root {
    top: -11px;
  }
  .MuiAutocomplete-popupIndicator {
    transform: none;
  }
  .MuiAutocomplete-clearIndicator {
    margin-right: 8px;
    svg {
      font-size: 20px;
    }
  }
  @media (max-width: 1199px) {
    .MuiAutocomplete-popper {
      inset: 16px auto auto 0px !important;
      width: 100vw !important;
      height: calc(100% - 68px);
    }
    .MuiAutocomplete-listbox {
      max-height: unset;
    }
    .MuiAutocomplete-paper {
      height: 100%;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`);

export default SearchBarStyle;
