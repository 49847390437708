import { Box, Typography, ListItemButton, ListItemIcon, ListItemText, Divider, List, ListItem } from '@mui/material';
import { useTranslation } from 'next-i18next';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import { NextImage } from '@components/atoms/image';
import Link from '@components/atoms/link';
import useUniversityMasterDegreeListing from '@hooks/useUniversityMasterDegreeListing';

const UniversityMasterDegreeContentListing = () => {
  const { t } = useTranslation('common');

  const { universityMasterDegreeList } = useUniversityMasterDegreeListing();

  const DEFAULT_IMAGE_PATH = '/static/icons/default-university-logo.svg';

  return (
    <>
      {universityMasterDegreeList?.map((university) => (
        <Box key={`university-${university.id}`}>
          <Box display="flex" justifyContent="space-between" columnGap={1} flexWrap="wrap">
            <Typography fontSize={18} color="#00000099">
              {university.displayName}
            </Typography>
            {university.isEnabled ? (
              <Link href={`/academic/${university.slug}`}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography fontSize={18} color="#00532A">
                    {t('menu.master_degree.view_university_information')}
                  </Typography>
                  <FontAwesomeIcon icon="fa-light fa-angle-right" style={{ fontSize: 18 }} color="#00532A" />
                </Box>
              </Link>
            ) : null}
          </Box>
          <Divider sx={{ my: 1 }} />
          <List style={{ overflowY: 'unset' }}>
            {university.degrees.map((degree) => (
              <Link href={`/academic/${university.slug}/degree/${degree.friendlyUrl}`}>
                <ListItem key={`degree-${degree.id}`} disablePadding>
                  <ListItemButton sx={{ marginX: -2, paddingX: 2 }}>
                    <ListItemIcon>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          position: 'relative',
                          width: 40,
                          height: 40,
                          borderRadius: 1,
                          border: '1px solid #0000001F',
                          overflow: 'hidden',
                        }}
                      >
                        <NextImage
                          src={university.logoImageUrl}
                          alt="university-logo"
                          layout="fill"
                          errorImage={DEFAULT_IMAGE_PATH}
                          objectFit="contain"
                        />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontSize={18} color="#000000DE" lineHeight={1}>
                          {degree.displayName}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      ))}
    </>
  );
};

export default UniversityMasterDegreeContentListing;
