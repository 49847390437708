import styled from '@emotion/styled';
import { MenuProps } from 'material-ui';
import Menu from 'material-ui-popup-state/HoverMenu';

const CategoryMenuStyle = styled(Menu)<MenuProps & { subcategory?: boolean }>`
  ${(props: any) => props.subcategory && 'margin-top: -17px; margin-left: -5px;'}
  .MuiPaper-root {
    padding-top: 4px;
    width: 230px;
    box-shadow: none;
    background: none;
    overflow: hidden;
    display: flex;
  }
  ul {
    width: 230px;
    margin: 5px;
    padding-top: 8px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    overflow: auto;
  }
`;

export default CategoryMenuStyle;
