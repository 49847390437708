import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const NotificationBoxStyle = styled(MenuItem)(
  ({ theme }) => `
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: flex-start;
    padding: 16px 20px 16px 16px;

    &.active {
      background: rgba(0, 83, 42, 0.1);;
    }

    .icon-container {
      .icon {
        background: #F5F5F5;
        border-radius: 50%;
        margin-right: 16px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 28px;
        }
      }
    }

    .message-container {
      padding-top: 4px;
      white-space: pre-line;
    
      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 22px;

        .title {
          font-size: 18px;
          font-weight: bold;
          font-family: Heavent;
        }

        .description {
          font-family: Lanna;
          font-size: 18px;
        }
      }
    }
  `,
);

export default NotificationBoxStyle;
