import { useMemo } from 'react';

import { CoreApiResponse, CoreApiStatusResponse } from '@common/type/CoreApiResponseTypes';
import { useRequest } from '@helpers/use';
import { swrImmutableOptions } from '@helpers/utils';
import { UniversityMasterDegree } from '@interfaces/university';
import universityAPI, { UNIVERSITY_ENDPOINT } from 'src/api/university';

const useUniversityMasterDegreeListing = () => {
  const { data, isLoading, isError } = useRequest<CoreApiResponse<UniversityMasterDegree[], CoreApiStatusResponse>>(
    UNIVERSITY_ENDPOINT.GET_UNIVERSITY_MASTER_DEGREE_LIST,
    universityAPI.getUniversityMasterDegreeList,
    { ...swrImmutableOptions },
  );

  const universityMasterDegreeList = useMemo(() => data?.data || null, [data]);

  return {
    universityMasterDegreeList,
    isLoading,
    isError,
  };
};

export default useUniversityMasterDegreeListing;
