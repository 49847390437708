import styled from '@emotion/styled';

const LogoStyle = styled.div`
  height: 44px;
  cursor: pointer;
  width: 140px;
  img {
    height: 44px;
  }
`;

export default LogoStyle;
