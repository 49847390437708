import { MenuProps } from 'material-ui';
import Menu from 'material-ui-popup-state/HoverMenu';
import styled from 'styled-components';

const MyCourseMenuStyle = styled(Menu)<MenuProps>`
  .MuiPaper-root {
    padding-top: 4px;
    width: 230px;
    box-shadow: none;
    background: none;
    overflow: hidden;
    display: flex;
  }
  ul {
    width: 230px;
    margin: 5px;
    padding-top: 8px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    overflow: auto;
  }
  .logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-top: 8px;
  }
`;

export default MyCourseMenuStyle;
