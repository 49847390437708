import config from 'src/config';
import Http from 'src/helpers/httpClient';

export const APP_END_POINT = 'v1/search';

export const SEARCH_END_POINT = {
  RECOMMEND: `${APP_END_POINT}/recommend`,
};

export default {
  fetchCoursesByKeyword(keyword: string) {
    const apiUrl = `${config.API_DOMAIN}/${SEARCH_END_POINT.RECOMMEND}?keyword=${encodeURIComponent(keyword.trim())}`;
    return Http.get(apiUrl, { isPublic: true });
  },
};
