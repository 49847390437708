import styled from '@emotion/styled';

const MenuItemLinkStyle = styled.div<{ isActive?: boolean }>`
  .item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .name {
      white-space: pre-wrap;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-right: 2px;
    }
  }
  ${(props: any) => props.isActive && 'background: rgba(0, 0, 0, 0.04);'}
  .MuiMenuItem-root {
    font-size: 18px;
    font-family: Lanna;

    .alert-dot {
      background: #e31b0c;
      width: 10px;
      height: 10px;
      border: 1px solid #ffffff;
      border-radius: 50%;
    }
  }
`;

export default MenuItemLinkStyle;
